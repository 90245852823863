.mr-2 {
    margin-right: 10px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-4 {
    margin-top: 40px;
}

.ml-3.md {
    margin-left: 30px !important;
}

.login-bg-img {
    /* background-image: url(/public/assets/images/bg.jpg); */
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* color: #1e4385; */
    overflow: auto;
    width: 100%;
    background: #fafbfc;
}

/* .bg-transparent{
    --background:transparent;
    color: #fff;
} */
/* .bg-transparent ion-card-header{
    --background: #1e458a;
} */
.bg-transparent ion-card-header ion-card-title {
    font-size: 23px;
    line-height: 30px;
    font-weight: 800;
    color: #333333;
    display: grid;
    font-style: normal;
}

.bg-transparent ion-card-header ion-card-title ion-text {
    float: left;
    color: #333333;
    font-weight: 100;
    font-size: 17px;
}

.bg-transparent ion-list,
.bg-transparent ion-item {
    --background: transparent;
    /* --background: #45464a; */
    background: transparent;
    --padding-end: 8px;
}

.bg-transparent ion-list ion-item .login-icon {
    position: absolute;
    right: 0;
    width: 25px;
    bottom: 8px;
}

/* .bg-transparent ion-list ion-item::part(native){
    color: #fff;
} */

ion-segment ion-segment-button.ios {
    --indicator-color: #1e458a;
}

ion-segment-button::part(native) {
    margin: 0;
}

/* .item-label-stacked.item-has-focus::part(native){
     border:3px solid red;
 } */

.signin-btn {
    width: 100%;
    --background: #fcd43c;
}

.segment-button-indicator-background {
    border-radius: var(--border-radius);
    background: rgb(30, 69, 138);
}

.m-auto {
    margin: auto;
}

#login ion-card {
    width: 400px;
    height: 506px;
    margin: auto;
    padding-top: 10px;
}

.h-100vh {
    height: 98vh;
    margin: 0px 70px;
}

.logo img {
    width: 80px;
    height: 80px;
    border-radius: 11px;
}

.logo-row h3 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
}

.logo-row h1 {
    font-size: 25px;
    font-weight: 800;
    margin: 0;
}

ion-row {
    color: #151D48;
}

.logo-below-text {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #151D48;
}
.logo-below-text p{
    margin: 0;
}
.bg-logo img{
    width: 532px;
}