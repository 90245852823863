.bg-grey {
    --background: #555a5d;
}

.m-auto {
    margin: auto;
}

/* category-form css */
.add-category .add-category-form ion-item {
    /* border: 1px solid #b6b6b6; */
    overflow: initial;
    margin-bottom: 35px;
    /* margin-top: 10px; */
}

.add-category .add-category-form ion-item.ios {
    margin-top: 10px;
}

.add-category .add-category-form ion-item ion-label {
    top: -21px;
    position: absolute;
    background: white;
    font-size: 120% !important;
}

.add-category .add-category-form ion-item::part(native) {
    --border-width: 3px;
}

.plt-desktop .add-category,
.plt-tablet .add-category {
    --width: 528px;
    --height: 420px;
}

.plt-ipad .add-category.ios {
    --height: 443px;
}

ion-modal ion-header ion-toolbar {
    --background: #c1c1c1;
}

ion-modal ion-header ion-toolbar ion-title {
    text-align: center;
}

ion-modal ion-header ion-toolbar ion-button ion-icon {
    font-size: 230%;
    color: dimgrey;
}

ion-modal ion-header ion-toolbar.ios {
    --padding-top: 10px;
    --padding-bottom: 10px;
}

/* .split-pane-maine{
    --side-max-width: 20% !important;
} */
/* ion-menu::part(container){
    --side-max-width: 20% !important;
} */
ion-menu.menu-pane-visible {
    max-width: 200px;
}

.plt-mobile .h-100vh {
    height: 98vh;
    margin: 0 0px;
}
.plt-mobile #login ion-card {
    height: 539px;
    width: 341px;
}
.leaflet-container {
    width: 100wh; 
    height: 100vh;
}
ion-list ion-list-header {
    background: #c1c1c1;
    min-height: 55px;
    font-weight: 400;
    font-size: 15px;
}

ion-list ion-item {
    font-size: 12px;
    font-weight: 400;
}

.idCol {
    min-width: 80px;
    max-width: 80px;
}

.nameCol, .addressCol  {
    min-width: 250px;
    max-width: 250px;
}

.imageCol{
    min-width: 280px;
    max-width: 280px;
}

.col-100 {
    min-width: 100px;
    max-width: 100px;
}

.col-120 {
    min-width: 120px;
    max-width: 120px;
}

.col-160 {
    min-width: 160px;
    max-width: 160px;
}