#dashboard{
    --background: #fafbfc;
}
#dashboard ion-card {
    height: 130px;
    /* width: 270px; */
    --ion-color-base: #FFF1D8 !important;
}

#dashboard ion-card ion-card-header {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#dashboard ion-card ion-card-header ion-card-title {
    font-weight: 600;
}

#dashboard .dashboard-avatar ion-avatar {
    margin: auto;
    position: relative;
    z-index: 999999;
    top: 40px;
    background: #f8c000;
}

#dashboard ion-avatar ion-icon {
    display: block;
    font-size: 2rem;
    margin: auto;
    position: relative;
    top: 15px;
    color: white;
}
.segments ion-segment{
    border: 1px solid #e4e4e4;
    border-radius: 11px;
    margin: 8px 0px;
}
.segments ion-segment ion-segment-button{
    --background: #e9e9e9;
    --background-checked: #ffffff;
    --color: #9e9e9e;
    --color-checked: #000000;
    --indicator-box-shadow: none;
    --indicator-color: #ffffff;
    font-size: 12px;
    --padding-bottom: 0px;
    --padding-top: 0px;
    min-height: 33px;
    --background-hover: #ffffff;
    --background-focused: #c4c2c2;
    --background-activated-opacity: none;
    --background-focused-opacity: none;
    --background-hover-opacity: none;
    text-transform: capitalize;
    border-right: 1px solid grey;
}
.segments ion-segment ion-segment-button:last-child{
    border-right: none;
}
.segments ion-segment ion-segment-button ion-label{
    margin: 0 !important;
}
.mb-30{
    margin-bottom: 30px;
}

.map-inputs ion-item:not(.checkBox){
    border: 1px solid grey;
    border-radius: 6px;
    --min-height: 35px;
}
.map-inputs ion-item ion-input,.map-inputs ion-item ion-select{
    --padding-bottom: 0;
    --padding-top: 0;
    width: 100%;
}
.map-inputs h5{
    margin: 0;
}
.map-inputs p{
    margin: 0;
    font-size: smaller;
    color: rgb(105, 105, 105);
}
.card-h-500{
    height: 700px !important;
}
.open-date-input{
    top: -25vh; left: 2vw;
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .submit-btn ion-button{
    --background: #f8c000;
  }
  .map-inputs ion-col{
    margin: auto;
  }
  