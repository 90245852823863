ion-list.lull-timePS {
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    padding: 0;
    height: 50vh;
    overflow-y: auto;
    overflow-x: auto;
}

ion-list.lull-timePS ion-list-header {
    min-width: 4200px;
}

ion-list.lull-timePS ion-item {
    min-width: 4200px;
    --padding-start: 0;
    --inner-padding-start: 16px;
}

/* ion-list.spots-list ion-list-header ion-label:nth-child(1),ion-list.spots-list ion-item ion-label:nth-child(1){
    max-width: 15%;
}
ion-list.spots-list ion-list-header ion-label:nth-child(2),ion-list.spots-list ion-item ion-label:nth-child(2){
    max-width: 40%;
} */

/* form css */
.add-form ion-item {
    /* border: 1px solid #b6b6b6; */
    overflow: initial;
    margin-bottom: 35px;
    /* margin-top: 10px; */
}

.add-form ion-item ion-label {
    top: -21px;
    position: absolute;
    background: white;
    font-size: 120% !important;
}

.add-form ion-item::part(native) {
    --border-width: 3px;
}

.add-profile ion-avatar {
    width: 150px;
    height: 150px;
    margin-left: 4vw;
}

.add-profile ion-item {
    text-align: center;
}

.plt-iphone .add-profile ion-avatar {
    margin-left: 12vw;
}