#rowReport {
    --background: #fafbfc;
}

#rowReport ion-accordion-group {
    margin: 10px;
}

#rowReport ion-accordion-group ion-accordion {
    margin-bottom: 10px;
    border-radius: 9px;
}

#rowReport ion-accordion-group ion-accordion ion-icon.person-icon {
    background: #f8c000;
    padding: 4px;
    border-radius: 7px;
    margin-right: 10px;
}