#lulltime .zone-time ion-col {
    /* background-color: #135d54; */
    /* border: solid 1px #fff; */
    color: #fff;
    text-align: center;
}

#lulltime .zone-time ion-col ion-badge {
    display: flex;
    justify-content: center;
    padding: 10px 1px;
    cursor: pointer;
}

#lulltime ion-col.side-heading-0 {
    /* border: solid 1px #fff; */
    text-align: left;
    padding: 13px 1px;
    font-weight: 600;
}

#lulltime ion-col.side-heading-1 {
    text-align: left;
    padding: 13px 1px;
}

ion-card#lulltime {
    height: 100% !important;
}

#lulltime ion-grid {
    padding: 0 0 0 15px;
}

#lulltime ion-col.side-heading-0 p {
    margin: 0;
}

ion-toggle {
    height: 10px;
    width: 65px;

    padding: 12px;

    --handle-width: 25px;
    --handle-height: 27px;
    --handle-max-height: auto;
    --handle-spacing: 6px;

    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
    contain: none;
}

ion-toggle::part(track),
ion-toggle.toggle-checked::part(track) {
    background: #ddd;
}

ion-toggle::part(handle) {
    background: #000;

    border-radius: 4px;
    box-shadow: none;
}

ion-toggle.toggle-checked::part(handle) {
    background: #f8c000;
}

.toggleDayNight{
    display: flex;
}
.toggleDayNight ion-icon{
    font-size: 25px;
    padding: 4px;
}