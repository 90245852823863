ion-list.user-list {
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    padding: 0;
    height: 50vh;
    overflow-y: auto;
}

/* ion-list.user-list ion-list-header {
    background: #c1c1c1;
    min-height: 55px;
    font-weight: 600;
    font-size: 110%;
} */

ion-list.user-list ion-item {
    --padding-start: 0;
    --inner-padding-start: 16px;
}

/* ion-list.user-list ion-list-header ion-label:nth-child(1),ion-list.user-list ion-item ion-label:nth-child(1){
    max-width: 15%;
}
ion-list.user-list ion-list-header ion-label:nth-child(2),ion-list.user-list ion-item ion-label:nth-child(2){
    max-width: 40%;
} */

.mx-w-04 {
    max-width: 4%;
}
.mx-w-17{
    max-width: 17%;
    min-width: 17%;
}
.mx-w-11{
    min-width: 150px;
    max-width: 150px;
    text-align: center;
}
.mx-w-20{
    min-width: 25%;
    max-width: 25%;
    white-space: normal !important;
}
/* form css */
.add-form ion-item {
    /* border: 1px solid #b6b6b6; */
    overflow: initial;
    margin-bottom: 35px;
    /* margin-top: 10px; */
}

.add-form ion-item ion-label {
    top: -21px;
    position: absolute;
    background: white;
    font-size: 120% !important;
}

.add-form ion-item::part(native) {
    --border-width: 3px;
}

.add-profile ion-avatar {
    width: 150px;
    height: 150px;
    margin-left: 4vw;
}

.add-profile ion-item {
    text-align: center;
}

.plt-iphone .add-profile ion-avatar {
    margin-left: 12vw;
}