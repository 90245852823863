.compare {
    margin: auto 17px;
    text-align: right;
}

.checkBox {
    border: none;
    --min-height: 35px;
}

.checkBox ion-checkbox {
    margin: 0px 10px;
}

.checkBox ion-checkbox ion-label {
    margin: 0;
}
#analysis {
    --background: #fafbfc;
}
#analysis ion-card#lulltime{
    height: auto !important;
}