ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }
  
  ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 0px;
    --padding-bottom: 20px;
  }
  
  /* ion-menu.md ion-list {
    padding: 20px 0;
  } */
  .ml-55{
      margin-left: 55px;
  }
  ion-menu.md ion-note {
    margin-bottom: 30px;
  }
  
  ion-menu.md ion-list-header, ion-menu.md ion-note {
    padding-left: 10px;
  }
  
  /* ion-menu.md ion-list#inbox-list {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  } */
  
  ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
  }
  
  ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: #757575;
    min-height: 26px;
  }
  
  ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
  }
  
  ion-menu.md ion-item.selected {
    --background: #F8C000;
    /* box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px; */
    color: white;
  }
  ion-menu ion-menu-toggle ion-item ion-label{
    font-size: 16px !important;
    font-weight: normal !important;
  }
  ion-menu ion-item ion-icon{
    font-size: 21px;
  }
  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-step-150);
  }
  ion-menu ion-item ion-icon{
    margin-inline-end: 15px;
  }
  ion-menu.md ion-item ion-icon {
    color: #616e7e;
  }
  
  ion-menu.md ion-item ion-label {
    font-weight: 600;
  }
  
  ion-menu.ios ion-content {
    --padding-bottom: 20px;
  }
  
  ion-menu.ios ion-list {
    padding: 20px 0 0 0;
  }
  
  ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
  }
  
  ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
  }
  
  ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
  }
  
  ion-menu.ios ion-item .selected ion-icon {
    color: var(--ion-color-primary);
  }
  
  ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
  }
  
  ion-menu.ios ion-list-header,
  ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  ion-menu.ios ion-note {
    margin-bottom: 8px;
  }
  
  ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
  }
.iot-icon{
    margin: 0px 23px 40px;
  }
  ion-item.selected {
    --color: var(--ion-color-step-150);
  } 
@media (prefers-color-scheme: light) {
     ion-menu ion-content, ion-menu ion-content ion-list, ion-menu ion-content ion-list ion-item {
      --background: #ffffff;
      background: #ffffff !important;
    }
    ion-menu.md ion-item {
        color: #575859;
    }
}

#inbox-list ion-item img{
  width: 50px;
  border-radius: 11px;
  margin-right: 20px;
}