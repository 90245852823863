.p-10-15{
    padding: 8px 15px;
}
.m-0{
    margin: 0;
}
/* .mb-10{
    margin-bottom: 8px;
} */

.spotImage {
    height: 200px;
    width: 250px;
}
ion-card-content p{
    color: #151d48;
    padding-bottom: 8px;
}
ion-card-header p{
    color: #151d48;
}
ion-card-content{
    padding-bottom: 5px;
}
#imageGalary .person-icon{
    background: #34207F;
    padding: 2px;
    border-radius: 7px;
    margin-right: 0px;
    position: absolute;
    right: -2px;
    bottom: -3px;
}

 .submit-btn ion-button {
     --background: #f8c000;
 }